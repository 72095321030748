import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { UserService } from '../services/user.service';
import { ModalService } from '../_modal';
import { ValidationMessages } from '../validation-messages';

@Component({
  selector: 'app-headerfront',
  templateUrl: './headerfront.component.html',
  styleUrls: ['./headerfront.component.css', '../../assets/fcss/bootstrap.css', '../../assets/fcss/default.css', '../../assets/fcss/AdminLTE.css', '../../assets/fcss/_all-skins.css']
})
export class HeaderfrontComponent implements OnInit {

  activeMenu: any;
  loggedStatus: any;
  loggedUserRole: any;
  loggedUserId: any;
  loggedUserName: any;
  quoteId: any;
  backgroundColor = '';
  logo_path = "";
  domainname: any='';
  nameofdomain = '';
  buttoncolor: any;

  loginUserName: any;
  loginPassword: any;
  loginRemberMe: any;
  domain:any;
  textColor:any;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  @Input() domainData:any = '';
  appversion: any;

  constructor(public router: Router, private modalService: ModalService, public dataService: DataService, public userService: UserService) { }

  ngOnInit() {
    if (typeof localStorage.getItem('u_id') != 'undefined' && localStorage.getItem('u_id') != null && localStorage.getItem('u_id') != 'null' && localStorage.getItem('u_id') != "") {
      this.goToQuotes();
    }
    else
    {
      this.loginUserName = localStorage.getItem('loginUserName');
      this.loginPassword = localStorage.getItem('loginPassword');
      this.loginRemberMe = localStorage.getItem('loginRememberme');
      this.appversion = localStorage.getItem("appversion");  
      localStorage.clear();
      localStorage.setItem('loginUserName', this.loginUserName);
      localStorage.setItem('loginPassword', this.loginPassword);
      localStorage.setItem('loginRememberme', this.loginRemberMe);
      localStorage.setItem("appversion", this.appversion);
      var domain = location.hostname + (location.port ? ':' + location.port : '');
      this.domain = domain;
     
        let wholesalerInfoArray = this.domainData;
       
        if (wholesalerInfoArray.output == 'success') { 
          this.backgroundColor = wholesalerInfoArray.wholesalerInfo[0].wi_header_bg_color_code;
          this.logo_path = wholesalerInfoArray.wholesalerInfo[0].wi_logo_s3_path;
          this.domainname = wholesalerInfoArray.wholesalerInfo[0].wi_domain;
          this.textColor = wholesalerInfoArray.wholesalerInfo[0].wi_text_bg_color_code;
          this.buttoncolor = wholesalerInfoArray.wholesalerInfo[0].wi_button_bg_color_code;
          if (this.domainname == ValidationMessages.stagingNormandyDomain || this.domainname == ValidationMessages.productionNormandyDomain || this.domainname == ValidationMessages.localNormandyDomain ) {
            this.nameofdomain = 'normandy';
            $('.main-header').addClass('normandy');
            this.favIcon.href = 'assets/images/favicon.png';
            document.getElementById("appTitle").innerHTML = "Normandy Insurance Company | A Workers' Compensation Carrier";
          }
          if (this.domainname == ValidationMessages.stagingCanopyDomain || this.domainname == ValidationMessages.productionCanopyDomain ) {
            this.nameofdomain = 'canopy';
            this.favIcon.href = 'assets/images/canopy_favicon.png';
            document.getElementById("appTitle").innerHTML = "Canopy Specialty Insurance LLC";
          }
          if (this.domainname == ValidationMessages.stagingPbibinsDomain || this.domainname == ValidationMessages.productionPbibinsDomain ) {
            this.nameofdomain = 'pbibins';
            this.favIcon.href = 'assets/images/pbib_favicon.png';
            document.getElementById("appTitle").innerHTML = "Pascal Burke Insurance Brokerage Inc. | Insurance Quotes | Sacramento, CA";
          }
    
          localStorage.setItem('wholesalerInfoObJ', JSON.stringify(wholesalerInfoArray));
        }
        else {
          this.logo_path = 'assets/images/logo.png?v='+ValidationMessages.logoVersion;
          this.favIcon.href = 'favicon.ico';
          document.getElementById("appTitle").innerHTML = "Insurance Rater";
          $(document).ready(function(){
            $('.navbar-static-top').css('padding-bottom', '0');
            $('.layout-top-nav').addClass('skin-blue');
            $('.navbar-brand').removeClass('normandy');
          })
         
        }
      

      this.loggedUserId = localStorage.getItem('u_id');
      this.loggedUserRole = localStorage.getItem('u_ur_id');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      this.dataService.currentLoggedStatus.subscribe(status => this.loggedStatus = status);
    }
  }
  
  goToHome() {
    localStorage.setItem('active_tab', '1');
    this.dataService.changeCurrentHeaderActive('1');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.router.navigate(['/home']);
  }

  goToQuotes() {
    this.loggedUserName = localStorage.getItem('u_email');
    this.dataService.changeCurrentHeaderDisplayName(this.loggedUserName);
    if (this.loggedUserRole == 3) {
      localStorage.setItem('brokerViewId', this.loggedUserId);
     
      if(localStorage.getItem('wholesalebroker') == '1' || localStorage.getItem('checkDisplayQuotes') == '1'){
        this.router.navigate(['/wbroker-quotes']);
      }else{
        this.router.navigate(['/broker-quotes']);
      }
    } else if (this.loggedUserRole == 4) {
      localStorage.setItem('active_tab', '0');
      this.dataService.changeCurrentHeaderActive('0');
      this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
      localStorage.setItem('new_quote_id', '2');
      this.router.navigate(['/quote-summary', this.quoteId]);
    } else {
      this.router.navigate(['/quotes']);
    }
  }

  goToFaqs() {
    localStorage.setItem('active_tab', '6');
    this.dataService.changeCurrentHeaderActive('6');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    
  }

  goToCompany() {
    localStorage.setItem('active_tab', '7');
    this.dataService.changeCurrentHeaderActive('7');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    
  }

  logoutPortal() {
    this.modalService.open('logout-modal');
  }

  logoutConfirmCancel() {
    this.modalService.close('logout-modal');
  }

  logoutConfirm() {
    this.modalService.close('logout-modal');
    localStorage.clear();
    localStorage.setItem('active_tab', '1');
    this.dataService.changeCurrentHeaderActive('1');
    this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
    this.dataService.changeCurrentLoggedStatus('0');
    this.dataService.currentLoggedStatus.subscribe(status => this.loggedStatus = status);
  }

}
