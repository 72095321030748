<div class="hold-transition layout-top-nav" >
    <div class="wrapper">
        <header class="main-header">
            <nav class="navbar navbar-static-top ">
                <!-- {{ isProduction ? '' : 'stag-theme-bg' }} -->
                <div class="container-fluid">
                    <div class="navbar-header">
                        <a href="javascript:void(0);" class="navbar-brand" (click)="goToHome()"><img class="header_logo"  src="{{logo_path}}"></a>
                        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse">
                            <i class="fa fa-bars"></i>
                        </button>
                    </div>
                   
                    <div class="collapse navbar-collapse pull-left" id="navbar-collapse">
                        <ul class="nav navbar-nav">
                            <li class="{{ activeMenu == 1 ? 'active' : '' }}"><a (click)="navbarcollapse();goToHome();" [ngStyle]=" {'color': textColor}"  style="cursor: pointer;">Home <span class="sr-only">(current)</span></a></li>
                            <li *ngIf="loggedUserRole != 4" class="{{ activeMenu == 2 ? 'active' : '' }}"><a style="cursor: pointer" [ngStyle]=" {'color': textColor}" (click)="navbarcollapse();goToQuotes()">Quotes</a></li>
                            <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2 || loggedPmaxUser == 1 || canhaveUserBroker == '1') && isLroAdmin != '1'" class="{{ activeMenu == 3 ? 'active' : '' }}"><a style="cursor: pointer" [ngStyle]=" {'color': textColor}" (click)="navbarcollapse();goToBrokers()">Brokers</a></li>
                            <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2) && isLroAdmin != '1'" class="{{ activeMenu == 4 ? 'active' : '' }}"><a style="cursor: pointer" [ngStyle]=" {'color': textColor}" (click)="navbarcollapse();goToClients()">Clients</a></li>
                            <li *ngIf="loggedUserRole == 1" class="{{ activeMenu == 5 ? 'active' : '' }}"><a style="cursor: pointer" [ngStyle]=" {'color': textColor}" (click)="navbarcollapse();goToAdmins()">Admins</a></li>
                            <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2) && isLroAdmin != '1'" class="{{ activeMenu == 9 ? 'active' : '' }}"><a style="cursor: pointer" [ngStyle]=" {'color': textColor}" (click)="navbarcollapse();goToClaims()">Claims</a></li>
                            <li *ngIf="isLroAdmin != '1'" class="{{ activeMenu == 10 ? 'active' : '' }}"><a style="cursor: pointer" [ngStyle]=" {'color': textColor}" (click)="navbarcollapse();goToTutorials()">Tutorials</a></li>
                            <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2) && isLroAdmin != '1'" class="{{ activeMenu == 8 ? 'active dropdown notifications-menu' : 'dropdown notifications-menu' }}">
                                <a style="cursor: pointer" [ngStyle]=" {'color': textColor}" class="dropdown-toggle" data-toggle="dropdown">Tools</a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <ul class="menu">
                                            <li>
                                                <a href="javascript:void(0);" (click)="navbarcollapse();goToReports()">
                                                    <i class="fa fa-user text-aqua"></i>Reports
                                                </a>
                                            </li>
                                            <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2) && show_release_notes == 'yes'" class="{{ activeMenu == 12 ? 'active' : '' }}">
                                                <a style="cursor: pointer" [ngStyle]=" {'color': textColor}" (click)="navbarcollapse();goToReleaseNotes()">
                                                    <i class="fa fa-sticky-note text-aqua"></i>Release Notes
                                                </a>
                                            </li>
                                            <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2) && show_master_settings == 'yes'" class="{{ activeMenu == 13 ? 'active' : '' }}">
                                                <a style="cursor: pointer" [ngStyle]=" {'color': textColor}" (click)="navbarcollapse();goToMasterSettings()">
                                                    <i class="fa fa-cog text-aqua"></i>Master Settings
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2) && !isProduction" class="{{ activeMenu == 11 ? 'active' : '' }}"><a style="cursor: pointer" [ngStyle]=" {'color': textColor}" (click)="navbarcollapse();goToPayments()">Payments</a></li>
                        </ul>
                    </div>
                    <div class="navbar-custom-menu">
                        <ul class="nav navbar-nav">
                    
                           
                            <li class="dropdown user user-menu notifications-menu">
                              
                                <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown">
                                  
                                    <i class="fa fa-user fa-2x user-image" aria-hidden="true"  [ngStyle]=" {'color': textColor}" ></i>
                                    <span class="hidden-xs" [ngStyle]=" {'color': textColor}">{{ loggedUserName }}</span>
                                </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <ul class="menu">
                                            <li *ngIf="loggedUserRole == 2 || loggedUserRole == 3">
                                                <a href="javascript:void(0);" (click)="goToProfile()">
                                                    <i class="fa fa-user text-aqua"></i>My Profile
                                                </a>
                                            </li>
                                            <li *ngIf="loggedUserRole == 3">
                                                <a href="javascript:void(0);" (click)="openChangePasswordModel()">
                                                    <i class="fa fa-user text-aqua"></i>Change Password
                                                </a>
                                            </li>
                                            <li *ngIf="loggedUserRole == 3">
                                                <a href="javascript:void(0);" (click)="goToJotForm()">
                                                    <i class="fa fa-user text-aqua"></i>Producer Agreement Form
                                                </a>
                                            </li>
                                            <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2) && isLroAdmin != '1'">
                                                <a href="javascript:void(0);" (click)="goToMailTemp()">
                                                    <i class="fa fa-envelope text-aqua"></i>Mail Templates
                                                </a>
                                            </li>
                                            <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2) && isLroAdmin != '1'">
                                                <a href="javascript:void(0);" (click)="goToMailSign()">
                                                    <i class="fa fa-envelope text-aqua"></i>Mail Signatures
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);" (click)="logoutPortal()">
                                                    <i class="fa fa-sign-out text-aqua"></i>Logout
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    </div>
</div>

<jw-modal id="view-all-notifications">
    <div class="modal-content modal_lg">
        <div class="modal-header">
            <h4 class="modal-title">All Notifications</h4>
            <div style="float:right;margin-top: -20px;">
                <a href="javascript:void(0);" (click)="markAllAsReadPop()" style="color:#f8d2d6">Mark All as Read</a>
            </div>
        </div>
        <div class="modal-body">
        
            <p-table [value]="hANotifications" [paginator]="true" [rows]="6" class="ng_table">
                <ng-template pTemplate="header">
                    <tr>
                        <th>From</th>
                        <th>Text</th>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-not>
                    <tr [class] = "not?.not_read_status == 1 ? 'table_unread' : 'table_read'">
                        <td>{{ not?.u_login_id }}</td>
                        <td><a href="javascript:void(0);" (click)="not?.not_type == 1 ? openQuoteNotPopup(not?.not_qu_id, not?.not_id) : openQuoteHistoryNotpopup(not?.not_qu_id, not?.not_id)"> {{ not?.not_text }}</a></td>
                        <td>{{ not?.not_created_at }}</td>
                        <td>{{ not?.not_read_status == 1 ? 'Unread' : 'Read' }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="modal-footer">
            <button (click)="closeNotificationPop()" class="btn btn-light">Cancel</button>&nbsp;&nbsp;
        </div>
    </div>
</jw-modal>

<jw-modal id="logout-modal">
    <div class="modal-content modal_sm">
        <div class="modal-header">
            <h4 class="modal-title">Message</h4>
        </div>
        <div class="modal-body">
            <p>Are you sure want to logout?</p>
        </div>
        <div class="modal-footer">
            <button (click)="logoutConfirm()" class="btn btn-primary">Yes</button>
            <button (click)="logoutConfirmCancel()" class="btn btn-light">Cancel</button>
        </div>
    </div>
</jw-modal>

<jw-modal id="add-edit-modal-header">
    <div class="modal-content modal_medium">
        <div class="modal-header">
            <h4 class="modal-title">Profile Details</h4>
        </div>
        <div class="modal-body">
            <form [formGroup]="adminFormH">
                <input type="hidden" formControlName="u_id">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="inputPassword3" class="col-sm-4 control-label">Full name</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" formControlName="u_name">
                                <div *ngIf="submittedh && fh.u_name.errors" class="invalid-feedback">
                                    <div *ngIf="fh.u_name.errors.required" style="color:red;font-size: 15px;">
                                       Full name is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="form-group">
                            <label for="inputPassword3" class="col-sm-4 control-label">Email</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" formControlName="u_email">
                                <div *ngIf="submittedh && fh.u_email.errors" class="invalid-feedback">
                                    <div *ngIf="fh.u_email.errors.required" style="color:red;font-size: 15px;">
                                        Email is
                                        required
                                    </div>
                                    <div *ngIf="fh.u_email.errors.email" style="color:red;font-size: 15px;">Email must be a valid email address</div>
                                </div>
                                <div *ngIf="serverEmailh" style="color:red;font-size: 15px;">Email already exists</div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="form-group">
                            <label for="inputPassword3" class="col-sm-4 control-label">Password</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" formControlName="u_password">
                                <p><small>If you want to change your password, please enter new password. Otherwise leave blank.</small></p>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button (click)="saveAdminH();" class="btn btn-primary">Save</button>&nbsp;&nbsp;
            <button (click)="closeModalH('add-edit-modal-header');" class="btn btn-light">Cancel</button>
        </div>
    </div>
</jw-modal>

<jw-modal id="change-pass-modal">
    <div class="modal-content modal_medium">
        <div class="modal-header" style="background: #C52033">
            <h4 class="modal-title">Change Password</h4>
        </div>
        <div class="modal-body">
            <form [formGroup]="updateBrokerPassForm">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="inputPassword3" class="col-sm-4 control-label">New Password</label>
                            <div class="col-sm-6">
                                <input type="password" (blur)="onChangePassword()" class="form-control" formControlName="up_pass">
                                <div *ngIf="submittedFA && ubp.up_pass.errors" class="invalid-feedback">
                                    <div *ngIf="ubp.up_pass.errors.required" style="color:red;font-size: 15px;">
                                        New password is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="inputPassword3" class="col-sm-4 control-label">Confirm Password</label>
                            <div class="col-sm-6">
                                <input type="password" class="form-control" formControlName="up_conform_pass">
                                <div *ngIf="submittedFA && ubp.up_conform_pass.errors" class="invalid-feedback">
                                    <div *ngIf="ubp.up_conform_pass.errors.required" style="color:red;font-size: 15px;">
                                        Confirm password is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="submittedFA && confirmmailerror" style="color:red;font-size: 15px; margin-left: 26px; margin-bottom: 12px; ">
            Please new password and confirm password must be same.
        </div>
        <div class="modal-footer">
            <button (click)="changePassword()" class="btn btn-primary">Submit</button>&nbsp;&nbsp;
            <button (click)="closeChangePasswordModel()" class="btn btn-light">Cancel</button>&nbsp;&nbsp;
        </div>
    </div>
</jw-modal>

<jw-modal id="warning-modal5">
    <div class="modal-content modal_sm">
        <div class="modal-header">
            <h4 class="modal-title">Message</h4>
        </div>
        <div class="modal-body">
            <p> Password Successfully Updated.</p>
        </div>
        <div class="modal-footer">
            <button (click)="closeWarnningModal();" class="btn btn-primary">Ok</button>&nbsp;&nbsp;
        </div>
    </div>
</jw-modal>